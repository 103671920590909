@import "~loaders.css/loaders.css";

$app-min-height: 550px;
$container-max-width: 1400px;
$screen-width-small: 900px;
$screen-width-tiny: 550px;

// GRID

$grid-width: 140rem;
$grid-wide: 100vw;

$gutter-xs: 0.8rem;
$gutter-s: 1.6rem;
$gutter-m: 3.2rem;
$gutter-l: 6.4rem;
// $gutter-text: .9rem;

$topnav-height: 4.5rem;
$calls-height: 16rem;
$callcard-height: 11rem;
$callcard-width: 30rem;
$status-icon-size: 1rem;

$border-radius-default: .4rem;


// DEFINED COLORS
$colors: (
  "gs-0": #ffffff,
  "gs-100": #f3f6fc,
  "gs-200": #e2e8f5,
  "gs-300": #d8dfef,
  "gs-400": #c8d1e6,
  "gs-500": #a0aece,
  "gs-600": #7f8ca8,
  "gs-700": #5e6980,
  "gs-800": #2a303e,
  "gs-900": #1d222d,
  "gs-1000": #15181f,
  
  /* Primary color swap */

  "prim-200": #D7EAF8,
  "prim-300": #99d2ff,
  "prim-400": #4d9ff2,
  "prim-500": #1265d4,
  "prim-600": #094c8d,
  "prim-700": #032943,

  /* Secondary color swap */

  "sec-300": #ffd6a8,
  "sec-400": #f5c489,
  "sec-500": #eaa459,
  "sec-600": #b27240,
  "sec-700": #794529,

  /* Utility colors */

  "amber-100": #fef3c7,
  "amber-400": #FBBF24,
  "amber-500": #f59e0b,
  "amber-700": #b45309,

  "green-100": #d1fae5,
  "green-400": #34D399,
  "green-500": #10b981,
  "green-700": #047857,

  "red-100": #fee2e2,
  "red-400": #F87171,
  "red-500": #ef4444,
  "red-700": #b91c1c,

  /* Status colors */

  "status-available": #10b981,
  "status-logged-out": #CFD8DC,
  "status-in-call": #f59e0b,
  "status-busy": #f59e0b,
  "status-away": #ef4444,
);

// TEXT
$font-text: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-ui: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-mono: "Roboto Mono", monospace;

$font-size-title: 3.8rem;
$font-size-xxl: 3.2rem;
$font-size-xl: 2.4rem;
$font-size-l: 1.8rem;
$font-size-m: 1.5rem;
$font-size-s: 1.3rem;
$font-size-xs: 1.1rem;

$font-size-code: 14px;

$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 700;

$letter-spacing-narrow: -0.2px; // for larger text
$letter-spacing-normal: 0.3px; // normal text
$letter-spacing-wide: 0.5px; // increased spacing for smaller text to be legible


// SHADOWS

$shadow-default: 0px 4px 16px #0000003D;
$shadow-1: 0 0.5rem 1.4rem -0.1rem rgba($color: #000, $alpha: 0.2);
$shadow-2: 0 .3rem 1.4rem 0 rgba($color: #000, $alpha: .2);

// UI ELEMENTS
$container-header-height: 8.2rem;
$container-header-height-compact: 5.5rem;

$list-item-height: 4.8rem;
$list-item-height-compact: 3.2rem;

$segment-height: 3.2rem;

$contacts-overview-item-width: 30rem;
$contacts-overview-item-height: 3.2rem;

$global-alert-height: 4.5rem;

$notifications-top-offset: 5rem;

$queue-details-stats-height: 6.5rem;

@mixin br-container {
    max-width: $container-max-width;
    padding: 0 16px;
    margin: 0 auto;
}

@mixin br-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.048), 0 3px 6px rgba(0, 0, 0, 0.075);
}

@mixin clearfix { // add this to rows and elements with floats
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin flexbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin board-heading {
    color: map-get($map: $colors, $key: gs-1000);
    padding: $gutter-s;
    display: flex;

    h3 {
        margin: auto;
        margin-right: $gutter-s;
        margin-left: $gutter-s;
        letter-spacing: 1px;
    }
}

@mixin subtitle($text-color) {
    display: block;
    font-weight: 500;
    font-size: $font-size-s;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-wide;
    color: $text-color;
}

@mixin btn-text {
    padding: $gutter-xs $gutter-s;
    font-size: $font-size-m;
    font-weight: $font-weight-normal;
    border-radius: $border-radius-default;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: $gutter-s;
    }
}
@import 'src/theme/variables';

.table-container {
  .table {
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid rgba(map-get($colors, 'gs-0'), 0.1);
    border-radius: $border-radius-default;
    overflow: hidden;

    .table-header {
      cursor: pointer;
      background-color: map-get($colors, 'gs-800');

      .table-header-cell {
        height: 40px;
        cursor: pointer;
        padding: 0 16px 0;
        border-bottom: 1px solid rgba(map-get($colors, 'gs-0'), 0.1);


        .header-content {
          display: flex;
          align-items: center;

          .header-title {
            color: rgba(map-get($colors, 'gs-0'), 0.9);
            font-size: $font-size-s;
            font-weight: $font-weight-normal;
            letter-spacing: $letter-spacing-wide;
          }

          .sort-icons {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-left: 8px;
            top: -8px;

            .sort-icon {
              font-size: 15px;
              color: rgba(map-get($colors, 'gs-0'), 0.5);
              position: absolute !important;

              &.active {
                color: rgba(map-get($colors, 'gs-0'), 0.9);
              }
            }
          }
        }
      }
    }

    .table-row {
      &:hover {
        background-color: map-get($colors, 'gs-800');
      }

      &.selected {
        background-color: map-get($colors, 'gs-800');
      }

      .table-cell {
        height: 48px;
        padding: 4px 16px;
        font-size: $font-size-m;
        font-weight: $font-weight-normal;
        line-height: 140%;
        letter-spacing: $letter-spacing-normal;
        color: rgba(map-get($colors, 'gs-0'), 0.9);
      }
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    height: 200px;
    padding: 40px 0 64px;

    .table-pagination {
      display: flex;
      align-items: center;
      gap: 6px;

      .pagination-button {
        height: 36px;
        cursor: pointer;
        border-radius: $border-radius-default;
        border: 1px solid rgba(map-get($colors, 'gs-0'), 0.1);
        background-color: rgba(map-get($colors, 'gs-0'), 0.1);
        padding: 0 12px;
        color: rgba(map-get($colors, 'gs-0'), 0.9);

        &.disabled {
          cursor: default;
          background-color: transparent;
          color: rgba(map-get($colors, 'gs-0'), 0.5);
        }

        .button-text {
          margin-left: 8px;
          font-size: $font-size-m;
          font-weight: $font-weight-normal;
        }
      }

      .pagination-page-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
        cursor: pointer;
        border-radius: $border-radius-default;
        color: rgba(map-get($colors, 'gs-0'), 0.9);

        &:hover {
          background-color: rgba(map-get($colors, 'gs-0'), 0.1);
        }

        &.active {
          background-color: rgba(map-get($colors, 'gs-0'), 0.1);
        }
      }

      .pagination-dots {
        margin: 0 8px;
        color: rgba(map-get($colors, 'gs-0'), 0.9);
      }
    }
  }
}


.no-borders {
  border-collapse: collapse;
}

.no-borders td,
.no-borders th {
  border: none;
}

table.rounded-corners {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  overflow: hidden;
}

/* Apply a border to the right of all but the last column */
table.rounded-corners th,
table.rounded-corners td {
  border: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

/* Apply a border to the bottom of all but the last row */
table.rounded-corners>thead>tr>th,
table.rounded-corners>thead>tr>td,
table.rounded-corners>tbody>tr>th,
table.rounded-corners>tbody>tr>td,
table.rounded-corners>tfoot>tr>th,
table.rounded-corners>tfoot>tr>td,
table.rounded-corners>tr>td,
table.rounded-corners>tr>th,
table.rounded-corners>thead,
table.rounded-corners>tbody,
table.rounded-corners>tfoot {
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Remove border from last column */
table.rounded-corners th:last-child,
table.rounded-corners td:last-child {
  border-right: none;
}

/* Remove border from last row */
table.rounded-corners tr:last-child>th,
table.rounded-corners tr:last-child>td {
  border-bottom: none;
}