@import "~loaders.css/loaders.css";

$app-min-height: 550px;
$container-max-width: 1400px;
$screen-width-small: 900px;
$screen-width-tiny: 550px;

// GRID

$grid-width: 140rem;
$grid-wide: 100vw;

$gutter-xs: 0.8rem;
$gutter-s: 1.6rem;
$gutter-m: 3.2rem;
$gutter-l: 6.4rem;
// $gutter-text: .9rem;

$topnav-height: 4.5rem;
$calls-height: 16rem;
$callcard-height: 11rem;
$callcard-width: 30rem;
$status-icon-size: 1rem;

$border-radius-default: .4rem;


// DEFINED COLORS
$colors: (
  "gs-0": #ffffff,
  "gs-100": #f3f6fc,
  "gs-200": #e2e8f5,
  "gs-300": #d8dfef,
  "gs-400": #c8d1e6,
  "gs-500": #a0aece,
  "gs-600": #7f8ca8,
  "gs-700": #5e6980,
  "gs-800": #2a303e,
  "gs-900": #1d222d,
  "gs-1000": #15181f,
  
  /* Primary color swap */

  "prim-200": #D7EAF8,
  "prim-300": #99d2ff,
  "prim-400": #4d9ff2,
  "prim-500": #1265d4,
  "prim-600": #094c8d,
  "prim-700": #032943,

  /* Secondary color swap */

  "sec-300": #ffd6a8,
  "sec-400": #f5c489,
  "sec-500": #eaa459,
  "sec-600": #b27240,
  "sec-700": #794529,

  /* Utility colors */

  "amber-100": #fef3c7,
  "amber-400": #FBBF24,
  "amber-500": #f59e0b,
  "amber-700": #b45309,

  "green-100": #d1fae5,
  "green-400": #34D399,
  "green-500": #10b981,
  "green-700": #047857,

  "red-100": #fee2e2,
  "red-400": #F87171,
  "red-500": #ef4444,
  "red-700": #b91c1c,

  /* Status colors */

  "status-available": #10b981,
  "status-logged-out": #CFD8DC,
  "status-in-call": #f59e0b,
  "status-busy": #f59e0b,
  "status-away": #ef4444,
);

// TEXT
$font-text: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-ui: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-mono: "Roboto Mono", monospace;

$font-size-title: 3.8rem;
$font-size-xxl: 3.2rem;
$font-size-xl: 2.4rem;
$font-size-l: 1.8rem;
$font-size-m: 1.5rem;
$font-size-s: 1.3rem;
$font-size-xs: 1.1rem;

$font-size-code: 14px;

$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 700;

$letter-spacing-narrow: -0.2px; // for larger text
$letter-spacing-normal: 0.3px; // normal text
$letter-spacing-wide: 0.5px; // increased spacing for smaller text to be legible


// SHADOWS

$shadow-default: 0px 4px 16px #0000003D;
$shadow-1: 0 0.5rem 1.4rem -0.1rem rgba($color: #000, $alpha: 0.2);
$shadow-2: 0 .3rem 1.4rem 0 rgba($color: #000, $alpha: .2);

// UI ELEMENTS
$container-header-height: 8.2rem;
$container-header-height-compact: 5.5rem;

$list-item-height: 4.8rem;
$list-item-height-compact: 3.2rem;

$segment-height: 3.2rem;

$contacts-overview-item-width: 30rem;
$contacts-overview-item-height: 3.2rem;

$global-alert-height: 4.5rem;

$notifications-top-offset: 5rem;

$queue-details-stats-height: 6.5rem;

@mixin br-container {
    max-width: $container-max-width;
    padding: 0 16px;
    margin: 0 auto;
}

@mixin br-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.048), 0 3px 6px rgba(0, 0, 0, 0.075);
}

@mixin clearfix { // add this to rows and elements with floats
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin flexbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin board-heading {
    color: map-get($map: $colors, $key: gs-1000);
    padding: $gutter-s;
    display: flex;

    h3 {
        margin: auto;
        margin-right: $gutter-s;
        margin-left: $gutter-s;
        letter-spacing: 1px;
    }
}

@mixin subtitle($text-color) {
    display: block;
    font-weight: 500;
    font-size: $font-size-s;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-wide;
    color: $text-color;
}

@mixin btn-text {
    padding: $gutter-xs $gutter-s;
    font-size: $font-size-m;
    font-weight: $font-weight-normal;
    border-radius: $border-radius-default;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: $gutter-s;
    }
}
.modal {
  border-radius: $border-radius-default;
  box-shadow: $shadow-2;
  background-color: rgba($color: map-get($map: $colors, $key: gs-1000), $alpha: .95);
  border: 0.1px solid rgba(255, 255, 255, 0.1);
  max-width: 600px;
  margin: 0 auto;
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 3rem;

  // NOTE: https://gitlab.iperitydev.com/compass/bridge/-/merge_requests/306#note_73997
  .button[color="primary"] {
    background-color: rgba(20, 29, 37, 0.9);
  }

  .input-wrapper {

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
      color: #FFFFFF;
      border: 0.1px solid rgba(255, 255, 255, 0.1);
      background: map-get($map: $colors, $key: gs-1000);

      &:focus {
        background-color: map-get($map: $colors, $key: gs-1000);
        border: 0.1px solid map-get($map: $colors, $key: sec-500);
      }

      &:focus::placeholder {
        color: map-get($map: $colors, $key: gs-600);
      }
    }
  }
}

@media (max-width: $screen-width-small) {
  .modal {
    width: 90%;
  }
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  transition: background-color .3s;
  background-color: transparent;

  .animated {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.modal__overlay--minimized {
  left: -99999px;
}

.modal__overlay--will-show {
  background-color: rgba($color: #000000, $alpha: 0);
}

.modal__overlay--will-hide {
  background-color: rgba($color: #000000, $alpha: 0);
}

.modal__overlay--hidden {
  display: none;
}

.modal__overlay--shown {
  background-color: rgba($color: map-get($map: $colors, $key: gs-1000), $alpha: .7);
}

.modal__title {
  font-size: 20px;
  font-weight: $font-weight-bold;
  margin-bottom: $gutter-s;
  color: map-get($map: $colors, $key: gs-400);
  padding-bottom: 0;
  padding-right: 0;
}

.modal__content {
  font-size: $font-size-m;
  color: map-get($map: $colors, $key: gs-400);
  font-weight: $font-weight-normal;
  line-height: $font-size-xl;
}

.modal__buttons {
  @include flexbox;
  padding-top: $gutter-m;

  .button {
    display: block;
    width: auto;
    margin-right: $gutter-s;
  }

  &--placement-right {
    justify-content: flex-end;

    .button {
      margin-right: 0;
      margin-left: $gutter-s;
    }
  }
}

.modal__close-btn {
  position: absolute;
  top: $gutter-m;
  right: $gutter-m;
  color: map-get($map: $colors, $key: gs-800);
  font-size: $font-size-m;
  color: #fff;
  cursor: pointer;
}

.modal--white {
  background-color: #FFFFFF;

  .modal__title {
    color: map-get($map: $colors, $key: gs-1000);
  }

  .modal__content {
    color: map-get($map: $colors, $key: gs-1000);
  }

  .modal__close-btn {
    color: map-get($map: $colors, $key: gs-1000);
  }
}

.modal-minimized {
  position: fixed;
  bottom: 0;
  right: 15%;
  background-color: rgba($color: map-get($map: $colors, $key: gs-1000), $alpha: .9);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-top-left-radius: $border-radius-default;
  border-top-right-radius: $border-radius-default;
  cursor: pointer;
  z-index: 300;

  &__title {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    color: map-get($map: $colors, $key: gs-400);
    margin-right: 3rem;
  }

  &__expand-btn {
    color: map-get($map: $colors, $key: gs-400);
  }
}

.modal--wide {
  max-width: 912px !important;
  width: 100%;
}

.modal--studio {
  border-radius: 8px;
  color: map-get($colors, 'gs-100');
  background-color: map-get($colors, 'gs-900');
  padding: 32px;

  .login-phone-modal__select-phone-items {
    margin: 0;
    padding: 0;
  }

  .modal__title {
    font-size: 24px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 116%;
    letter-spacing: 0.96px;
    color: map-get($colors, 'gs-100');
    margin-bottom: 32px;
  }

  .modal__content {
    color: map-get($colors, 'gs-100');
    font-size: $font-size-m;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 140%;
    letter-spacing: 0.42px;
  }

  .login-phone-modal__user-phone-info {
    margin-bottom: 32px;
  }

  .login-phone-modal__select-phone-items {
    height: 377px;

  }

  .login-phone-modal__select-phone-button-wrap {
    margin-left: 0;
    margin-right: 16px;
  }

  .login-phone-modal__select-phone-buttons {
    padding-top: 32px;
  }
}