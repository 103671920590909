@import "~loaders.css/loaders.css";

$app-min-height: 550px;
$container-max-width: 1400px;
$screen-width-small: 900px;
$screen-width-tiny: 550px;

// GRID

$grid-width: 140rem;
$grid-wide: 100vw;

$gutter-xs: 0.8rem;
$gutter-s: 1.6rem;
$gutter-m: 3.2rem;
$gutter-l: 6.4rem;
// $gutter-text: .9rem;

$topnav-height: 4.5rem;
$calls-height: 16rem;
$callcard-height: 11rem;
$callcard-width: 30rem;
$status-icon-size: 1rem;

$border-radius-default: .4rem;


// DEFINED COLORS
$colors: (
  "gs-0": #ffffff,
  "gs-100": #f3f6fc,
  "gs-200": #e2e8f5,
  "gs-300": #d8dfef,
  "gs-400": #c8d1e6,
  "gs-500": #a0aece,
  "gs-600": #7f8ca8,
  "gs-700": #5e6980,
  "gs-800": #2a303e,
  "gs-900": #1d222d,
  "gs-1000": #15181f,
  
  /* Primary color swap */

  "prim-200": #D7EAF8,
  "prim-300": #99d2ff,
  "prim-400": #4d9ff2,
  "prim-500": #1265d4,
  "prim-600": #094c8d,
  "prim-700": #032943,

  /* Secondary color swap */

  "sec-300": #ffd6a8,
  "sec-400": #f5c489,
  "sec-500": #eaa459,
  "sec-600": #b27240,
  "sec-700": #794529,

  /* Utility colors */

  "amber-100": #fef3c7,
  "amber-400": #FBBF24,
  "amber-500": #f59e0b,
  "amber-700": #b45309,

  "green-100": #d1fae5,
  "green-400": #34D399,
  "green-500": #10b981,
  "green-700": #047857,

  "red-100": #fee2e2,
  "red-400": #F87171,
  "red-500": #ef4444,
  "red-700": #b91c1c,

  /* Status colors */

  "status-available": #10b981,
  "status-logged-out": #CFD8DC,
  "status-in-call": #f59e0b,
  "status-busy": #f59e0b,
  "status-away": #ef4444,
);

// TEXT
$font-text: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-ui: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-mono: "Roboto Mono", monospace;

$font-size-title: 3.8rem;
$font-size-xxl: 3.2rem;
$font-size-xl: 2.4rem;
$font-size-l: 1.8rem;
$font-size-m: 1.5rem;
$font-size-s: 1.3rem;
$font-size-xs: 1.1rem;

$font-size-code: 14px;

$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 700;

$letter-spacing-narrow: -0.2px; // for larger text
$letter-spacing-normal: 0.3px; // normal text
$letter-spacing-wide: 0.5px; // increased spacing for smaller text to be legible


// SHADOWS

$shadow-default: 0px 4px 16px #0000003D;
$shadow-1: 0 0.5rem 1.4rem -0.1rem rgba($color: #000, $alpha: 0.2);
$shadow-2: 0 .3rem 1.4rem 0 rgba($color: #000, $alpha: .2);

// UI ELEMENTS
$container-header-height: 8.2rem;
$container-header-height-compact: 5.5rem;

$list-item-height: 4.8rem;
$list-item-height-compact: 3.2rem;

$segment-height: 3.2rem;

$contacts-overview-item-width: 30rem;
$contacts-overview-item-height: 3.2rem;

$global-alert-height: 4.5rem;

$notifications-top-offset: 5rem;

$queue-details-stats-height: 6.5rem;

@mixin br-container {
    max-width: $container-max-width;
    padding: 0 16px;
    margin: 0 auto;
}

@mixin br-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.048), 0 3px 6px rgba(0, 0, 0, 0.075);
}

@mixin clearfix { // add this to rows and elements with floats
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin flexbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin board-heading {
    color: map-get($map: $colors, $key: gs-1000);
    padding: $gutter-s;
    display: flex;

    h3 {
        margin: auto;
        margin-right: $gutter-s;
        margin-left: $gutter-s;
        letter-spacing: 1px;
    }
}

@mixin subtitle($text-color) {
    display: block;
    font-weight: 500;
    font-size: $font-size-s;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing-wide;
    color: $text-color;
}

@mixin btn-text {
    padding: $gutter-xs $gutter-s;
    font-size: $font-size-m;
    font-weight: $font-weight-normal;
    border-radius: $border-radius-default;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: $gutter-s;
    }
}
html, body, #app {
  height: 100%;
  font-weight: $font-weight-normal;
  font-family: $font-ui;
  letter-spacing: $letter-spacing-normal;
}
*, *:before, *:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
*:focus{
  outline: none;
}

.br-text-center {
  text-align: center;
}
.br-text-right {
  text-align: right;
}
.br-padding {
  padding: $gutter-s;
}

// Font sizes
.br-text-size--title {
  font-size: $font-size-title;
}
.br-text-size--xxl {
  font-size: $font-size-xxl;
}
.br-text-size--xl {
  font-size: $font-size-xl;
}
.br-text-size--l {
  font-size: $font-size-l;
}
.br-text-size--m {
  font-size: $font-size-m;
}
.br-text-size--s {
  font-size: $font-size-s;
}
.br-text-size--xs {
  font-size: $font-size-xs;
}
.br-text-size--code {
  font-size: $font-size-code;
}

@each $name, $color in $colors {
  .br-color--#{$name} {
    color: $color;
  }
}

@media (max-width: $screen-width-small) {
  .br-screen-large {
    display: none !important;
  }
}

@media (min-width: $screen-width-small) {
  .br-screen-small {
    display: none !important;
  }
}

/* React Tooltip */
.br-tooltip {
  white-space: nowrap;
  font-size: $font-size-s;
  padding: .5rem $gutter-xs;
  &.type-dark {
    background-color: rgba($color: map-get($map: $colors, $key: gs-1000), $alpha: .9) !important;
  }
}


/* Tippy tooltip "bridge" theme */
.tippy-tooltip.bridge-theme {
  padding: .5rem $gutter-xs;
  font-size: $font-size-s;
  background-color: map-get($map: $colors, $key: gs-900);
  box-shadow: $shadow-default;
}
.tippy-tooltip.bridge-theme[x-placement^='top'] .tippy-arrow {
  border-top-color: map-get($map: $colors, $key: gs-900);
  box-shadow: $shadow-default;
  bottom: -8px;
}
.tippy-tooltip.bridge-theme[x-placement^='bottom'] .tippy-arrow {
  border-bottom-color: map-get($map: $colors, $key: gs-900);
  box-shadow: $shadow-default;
}
.tippy-tooltip.bridge-theme[x-placement^='left'] .tippy-arrow {
  border-left-color: map-get($map: $colors, $key: gs-900);
  box-shadow: $shadow-default;
}
.tippy-tooltip.bridge-theme[x-placement^='right'] .tippy-arrow {
  border-right-color: map-get($map: $colors, $key: gs-900);
  box-shadow: $shadow-default;
}

/* Tippy onboarding */
.br-onboarding-tooltip {
  max-width: 500px !important;
}
.br-onboarding-tooltip {
  border-radius: 10px;
}
.tippy-popper[x-placement^=bottom] {
  .br-onboarding-tooltip {
    top: 15px;
    .tippy-arrow {
      border-bottom-width: 16px;
      border-right: 16px solid transparent;
      border-left: 16px solid transparent;
      top: -15px;
    }
  }
}
.tippy-popper[x-placement^=right] {
  .br-onboarding-tooltip {
    left: 15px;
    .tippy-arrow {
      border-right-width: 16px;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      left: -15px;
    }
  }
}
.tippy-popper[x-placement^=left] {
  .br-onboarding-tooltip {
    right: 15px;
    .tippy-arrow {
      border-left-width: 16px;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      right: -15px;
    }
  }
}

/* Context Menu */
.react-contextmenu {
  top: $topnav-height;
  background-color: rgba($color: map-get($map: $colors, $key: gs-1000), $alpha: .8);
  border-radius: $border-radius-default;
  min-width: 12rem;
  border: 1px solid map-get($map: $colors, $key: gs-400);
  text-align: right;
  min-width: 160px;
  outline: none;
  opacity: 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
  z-index: 9999;
  overflow: hidden;
  &:focus, *:focus{
    outline: none;
  }
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
}

.react-contextmenu-item {
  font-size: $font-size-s;
  color: map-get($map: $colors, $key: gs-200);
  font-weight: $font-weight-normal;
  padding: $gutter-xs $gutter-s;
  text-align: left;
  cursor: pointer;
  text-align: inherit;
  white-space: nowrap;
  &:first-child {
    border-radius: $border-radius-default $border-radius-default 0 0;
  }
  &:last-child {
      border-radius: 0 0 $border-radius-default $border-radius-default;
  }
  &:hover,
  &:focus {
      background-color: rgba($color: map-get($map: $colors, $key: gs-1000), $alpha: .8);
  }
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: rgba($color: map-get($map: $colors, $key: gs-1000), $alpha: .8);
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  cursor: default;
  color: darken(map-get($map: $colors, $key: gs-400), 25%);
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0,0,0,.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}


.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.br-content-loader {
  min-height: 150px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Form controls
.br-form-group {
  margin-bottom: 48px;
  
  select {
    min-width: 400px;
    position: relative;
    height: 48px;
    border: 1px solid map-get($map: $colors, $key: gs-900);
    background: map-get($map: $colors, $key: gs-1000);
    color: map-get($map: $colors, $key: gs-100);
    border-radius: 8px;
    cursor: pointer;
    padding: 0 16px;
  }
  &--disabled {
    .br-form-label,
    .br-form-info {
      opacity: .3;
    }
  } 
}

.br-form-label {
  font-size: 1.4rem;
  color: map-get($map: $colors, $key: gs-100);
  margin-bottom: 15px;
  font-weight: bold;
}

.br-form-info {
  color: map-get($map: $colors, $key: gs-600);
  font-size: 14px;
  margin-bottom: 24px;
  a, button {
    color: map-get($map: $colors, $key: gs-300);
  }
  button{
    background:transparent;
    border:0;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }
}

.br-form-divider {
  border-bottom: 1px solid map-get($map: $colors, $key: gs-800);
  margin-bottom: 48px;
}
